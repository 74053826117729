import React from 'react';
import { createTheme , NextUIProvider } from '@nextui-org/react';

const theme = createTheme({
  type: "light",
  theme: {
    colors: {
      primary: "$cyan600",
      primaryLight: "$cyan600",
      primaryLightHover: "$cyan800",
      primaryLightContrast: "$cyan800",
    }
  }



})

export const wrapRootElement = ({ element }) => {
  return (
    <NextUIProvider theme={theme}>
      {element}
    </NextUIProvider>
  );
};
